<template>
  <section class="contact">
    <div class="contact__container">
      <div>
        <h1 class="contact__cta-header --cta-header">
          Contact Us
        </h1>
        <div class="contact__form-content">
          <transition appear name="fade-slow" mode="out-in">
            <h5 v-if="formSubmitted">
              Thanks for reaching out, {{ models.firstName }}. We'll be in touch
              soon!
            </h5>
            <form
              v-else
              key="contactForm"
              class="contact__form form"
              data-aos="fade-in"
              @submit.prevent
              @keyup.enter="submitContactForm"
              autocomplete="off"
            >
              <div
                class="input-container"
                v-for="input in schema"
                :key="input.model"
                :class="'--w-' + ('width' in input ? input.width : '100')"
              >
                <!--<label class="input__label">
                  {{ input.label }}
                </label>-->
                <textarea
                  v-if="input.type === 'textarea'"
                  :name="input.name"
                  :ref="input.name"
                  :placeholder="input.placeholder"
                  v-model="models[input.model]"
                  :class="{ error: input.error !== '' }"
                  class="input"
                  autocomplete="off"
                />
                <input
                  v-else
                  :type="input.type"
                  :name="input.name"
                  :ref="input.name"
                  :placeholder="input.placeholder"
                  v-model="models[input.model]"
                  :class="{ error: input.error !== '' }"
                  class="input"
                  autocomplete="off"
                />
                <transition appear name="fade-slow" mode="out-in">
                  <div v-if="input.error" class="input__error">
                    {{ input.error }}
                  </div>
                </transition>
              </div>
              <div class="contact__submit-container" style="width: 100%; display: flex; justify-content: flex-end;">
                <button
                  type="submit"
                  class="contact__submit --primary"
                  @click="submitContactForm"
                >
                  Submit
                </button>
              </div>
            </form>
          </transition>
        </div>
      </div>
      <div class="contact__side-panel">
        <h4 class="contact__side-panel-header">Get in touch with us.</h4>
        <p style="font-size: 0.95em !important;">
          Let's discuss your performance goals and see what we can do to improve
          your ROI and better utilize your shopper data.
        </p>
        <div style="margin-top: 1rem;">
          <h6 class="--color-accent --font-weight-normal">
            Address
          </h6>
          <p>
            Jumpfeed, LLC<br />
            2027 W. Division St. #185<br />
            Chicago, IL 60622
          </p>
          <h6 class="--color-accent --font-weight-normal">
            Support
          </h6>
          <p>
            <a href="mailto:support@jumpfeed.com">support@jumpfeed.com</a><br />
            <a href="tel:+18449012239">+1 (844) 901-2239</a>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import formValidation from "../utils/formValidation";
export default {
  name: "Contact",
  metaInfo: {
    titleTemplate: "%s | Contact",
    link: [{ rel: "canonical", href: "https://www.jumpfeed.com/contact" }]
  },
  data() {
    return {
      formSubmitted: false,
      models: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        message: ""
      },
      schema: [
        {
          model: "firstName",
          type: "text",
          label: "First Name",
          placeholder: "First Name",
          validator: "Name",
          required: true,
          error: "",
          width: "50"
        },
        {
          model: "lastName",
          type: "text",
          label: "Last Name",
          placeholder: "Last Name",
          validator: "Name",
          required: true,
          error: "",
          width: "50"
        },
        {
          model: "email",
          type: "text",
          label: "Email Address",
          placeholder: "Email Address",
          validator: "Email",
          required: true,
          error: "",
          width: "50"
        },
        {
          model: "phone",
          type: "text",
          label: "Phone (Optional)",
          placeholder: "Phone (Optional)",
          validator: "Phone",
          required: false,
          error: "",
          width: "50"
        },
        {
          model: "message",
          type: "textarea",
          label: "Message",
          placeholder: "Message",
          validator: "String",
          required: true,
          error: ""
        }
      ]
    };
  },
  methods: {
    submitContactForm(e) {
      e.preventDefault();
      let validatorResponse = formValidation.validateForm(
        this.schema,
        this.models
      );
      if (validatorResponse.validated) {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        this.sendContactEmail();
        this.formSubmitted = true;
      } else {
        this.schema = validatorResponse.inputs;
      }
      return false;
    },
    sendContactEmail() {
      this.$http
        .post("https://api.jumpfeed.com/internal/send-contact-email", {
          key: "a7273sd633_fn209wknr290kwe284_m784r",
          contact: this.models
        })
        .then(response => {
          console.log(response);
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  }
};
</script>
<style scoped lang="scss">
.contact {
  padding-top: 5rem;
  background-image: radial-gradient(#1a1a1a 33%, transparent 33%);
  background-size: 3px 3px;

  @include screenMedium {
    padding-top: 6.5rem;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: inline-block;
    background: linear-gradient(
      to bottom,
      rgba(15, 14, 15, 0),
      rgb(15, 14, 15)
    );
  }

  .contact__container {
    position: relative;
    z-index: 1;

    @include containWidth();
    @include defaultContainerPadding();

    @include displayFlex(row, space-between, flex-start);

    @include screenMedium {
      @include displayFlex(column, flex-start, flex-start);
    }

    .contact__form-content {
      padding-top: $gap-large;
      padding-right: $gap-very-large;
      min-width: 500px;

      @include screenMedium {
        padding-right: 0;
        min-width: unset;
      }
    }

    .contact__side-panel {
      padding-top: $gap-standard;
      padding-left: $gap-very-large;
      max-width: 600px;
      border-left: 1px solid $base-dark;

      @include screenMedium {
        margin-top: $gap-very-large;
        padding-left: 0;
        max-width: unset;
        border: none;
      }

      .contact__side-panel-header {
        font-weight: normal;
        margin-bottom: $gap-standard;
      }

      p {
        color: $base-dark;
        margin-bottom: $gap-large;
        font-size: 0.9em;
      }

      a {
        color: $base-dark;
        text-decoration: none;
        @include hoverLighten();
      }
    }
  }

  ::placeholder,
  :-ms-input-placeholder,
  ::-ms-input-placeholder {
    color: $base;
    opacity: 1;
  }
}
</style>
